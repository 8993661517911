<script setup lang="ts">
import { BucketFileId, typeMap } from '@respell/utils';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import AppCarbonFilePond from '~/components/app/AppCarbonFilePond.vue';
import VariableNode from '~/components/app/VariableNode.vue';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';
import { variableRegex } from '~/stores/canvas';

interface FileObject {
  source: string;
  options: {
    type: string;
  };
}

const modelValue = defineModel<
  BucketFileId | BucketFileId[] | string | string[] | undefined
>();

const props = defineProps({
  // ownerType corresponds to the Type of the object that owns the file, IE: Spell, SpellRun, Workspace
  ownerType: {
    type: String,
    required: true,
  },
  // ownerId corresponds to the id of the object that owns the file, IE: yjUYTvV3c73oAdiQ0ETtK
  ownerId: {
    type: String,
    required: true,
  },
  // We allow multiple on the DataSources page
  allowMultiple: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'file/document',
  },
  injectable: {
    type: Boolean,
    default: false,
  },
  acceptedFileTypes: {
    type: Array as PropType<string[]> | undefined,
    default: undefined,
  },
  onSuccess: {
    type: Function,
    required: false,
  },
  folderId: {
    type: String,
    required: false,
  },
});

const fileName = ref(undefined);
const fileNameDisplay = computed(() => {
  if (props.allowMultiple) {
    if (modelValue.value?.length) {
      return `${modelValue.value.length} file(s) selected`;
    } else {
      return 'Select files';
    }
  } else {
    if (modelValue.value) {
      return fileName.value;
    } else {
      return 'Select file';
    }
  }
});

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const workspaceStore = useWorkspaceStore();
const { workspaceId } = storeToRefs(workspaceStore);

const { emitFormChange } = useFormGroup();

const myFiles = ref<FileObject[]>([]);

const sourceByBucketFileId = (value: string): string => {
  const bucketFileId: BucketFileId = new BucketFileId(String(value));

  const { ownerType, ownerId, fileId } = bucketFileId;
  const source = `/api/files/${fileId}?ownerType=${ownerType}&ownerId=${ownerId}&customerId=${workspaceId.value}`;

  return source;
};

const bucketFileIdBySource = (source: string): BucketFileId => {
  const fileIdMatch = source.match(/\/files\/(\d+)/);
  const fileId = fileIdMatch ? fileIdMatch[1] : null;

  const params = new URLSearchParams(source.split('?')[1]);
  const ownerType = params.get('ownerType');
  const ownerId = params.get('ownerId');
  const customerId = params.get('customerId');

  return new BucketFileId(ownerType, ownerId, customerId, fileId);
};

const beforeRemoveFile = (item) => {
  // FilePond is about to remove this file, return false to prevent removal, or return a Promise and resolve with true or false.
  const { source } = item; // Useful: serverId is the integer id if needed

  if (typeof source === 'string') {
    // If source is a string, it represents the URL the file was fetched from and revert is not called automatically
    const deletedBucketFileId: BucketFileId = bucketFileIdBySource(source);

    $api(source, { method: 'DELETE' });

    if (props.allowMultiple) {
      const updatedModelValue = modelValue.value.filter(
        (bucketFileId) => String(bucketFileId) !== String(deletedBucketFileId),
      );

      modelValue.value = updatedModelValue;
    } else {
      modelValue.value = undefined;
      fileName.value = undefined;
    }

    return true;
  } else if (typeof source === 'object') {
    // If source is a File (object), return true because allow `revert` to remove it
    return true;
  }

  return false;
};

const myServer = computed(() => ({
  process: async (
    fieldName: any,
    file: any,
    metadata: any,
    load: any,
    error: any,
  ) => {
    // We turn off the modelValue watcher since filepond's `load` will take it from here
    unwatch();

    try {
      const tags = {
        ownerType: props.ownerType,
        ownerId: props.ownerId,
        creatorId: user.value?.id,
        workspaceId: workspaceId.value,
        mimeType: file.type,
      };

      if (props.folderId) {
        tags.folderId = props.folderId;
      }

      // Get presigned URL from our endpoint
      const uploadResponse = await $api('/api/assets', {
        method: 'POST',
        body: {
          filename: file.name,
          contentType: file.type,
          workspaceId: workspaceId.value,
          tags,
        },
      });

      if (uploadResponse) {
        // Upload directly to S3 using presigned URL
        await $fetch(uploadResponse.presignedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        // Create BucketFileId with numeric asset ID
        const bucketFileId = new BucketFileId(
          props.ownerType,
          props.ownerId,
          workspaceId.value,
          uploadResponse.assetId.toString(),
        );

        if (props.allowMultiple) {
          if (!Array.isArray(modelValue.value)) {
            modelValue.value = [];
          }
          modelValue.value.push(bucketFileId);
        } else {
          fileName.value = file.name;
          modelValue.value = bucketFileId;
        }

        if (props.onSuccess) {
          props.onSuccess();
        }

        load(uploadResponse.assetId);
        emitFormChange();
      }
    } catch (e) {
      error('Sorry, something went wrong. Please try again.');
    }
  },

  load: async (
    source: any,
    load: any,
    error: any,
    progress: any,
    abort: any,
    headers: any,
  ) => {
    try {
      const result = await $api<any>(`${source}`);

      if (!props.allowMultiple) {
        fileName.value = result.name;
      }

      if (result.presignedUrl) {
        const response = await $fetch<Blob>(result.presignedUrl);

        // Construct File object with proper name and type
        const newFile = new File([response], result.name, {
          type: result.mimeType,
        });

        progress(true, 0, 1024);
        load(newFile);
      }
    } catch (e: any) {
      if (e.data?.statusCode === 404) {
        error(e.data.message);
      } else {
        error('Something went wrong.');
      }
    }
  },

  revert: async (uniqueFileId: any, load: any, error: any) => {
    try {
      await $api(`/api/assets/${uniqueFileId}`, { method: 'DELETE' });

      if (props.onSuccess) {
        props.onSuccess();
      }

      const deletedBucketFileId = new BucketFileId(
        props.ownerType,
        props.ownerId,
        workspaceId.value,
        uniqueFileId,
      );

      if (props.allowMultiple) {
        const updatedModelValue = modelValue.value.filter(
          (bucketFileId) =>
            String(bucketFileId) !== String(deletedBucketFileId),
        );
        modelValue.value = updatedModelValue;
      } else {
        modelValue.value = undefined;
        fileName.value = undefined;
      }

      emitFormChange();
      load();
    } catch (e) {
      error('Something went wrong.');
    }
  },
}));

const isVariable = computed(() => {
  if (typeof modelValue.value === 'string') {
    const matches = modelValue.value.match(variableRegex);
    return matches;
  }
  return false;
});

// We watch modelValue to load the content if a bucketFileId is provided during or after mount
// However, we unwatch if the user calls `process` on new data as we do not need sources to be loaded twice
const unwatch = watch(
  modelValue,
  async () => {
    // When the component mounts, if we have a bucketFile we create the URL to hit our endpoint to retrieve a presignedUrl file blob
    if (
      modelValue.value !== null &&
      modelValue.value !== undefined &&
      [].concat(modelValue.value).length > 0 &&
      !isVariable.value
    ) {
      // Shorthand to handle a string or array as input
      const values = [].concat(modelValue.value);

      myFiles.value = values.map((value) => {
        return {
          source: sourceByBucketFileId(value),
          options: {
            type: 'local',
          },
        };
      });

      // If this is used in variable injection, we need to load the file object to retrieve the file name to show in the dropdowns
      if (props.injectable && !props.allowMultiple) {
        myServer.value.load(sourceByBucketFileId(modelValue.value));
      }
    }
  },
  { immediate: true },
);
</script>
<template>
  <VariablePicker
    v-if="injectable"
    v-model="modelValue"
    :type="type"
    @update:model-value="emitFormChange"
  >
    <template #button>
      <UButton
        trailing-icon="i-ph-caret-down"
        color="white"
        block
        variant="solid"
        size="lg"
        :ui="{ font: 'font-normal', block: 'justify-between' }"
      >
        <VariableNode v-if="isVariable" v-model="modelValue" size="sm" />
        <span v-else class="flex flex-row items-center gap-2">
          <UIcon :name="typeMap[props.type].icon" class="w-5 h-5" />
          <span>{{ fileNameDisplay }}</span>
        </span>
      </UButton>
    </template>
    <template #file>
      <AppCarbonFilePond
        :accepted-file-types="acceptedFileTypes"
        :editable="editable"
        :allow-multiple="allowMultiple"
        :type="props.type"
        :files="myFiles"
        :server="myServer"
        :before-remove-file="beforeRemoveFile"
      />
    </template>
  </VariablePicker>
  <AppCarbonFilePond
    v-else
    :editable="editable"
    :allow-multiple="allowMultiple"
    :files="myFiles"
    :server="myServer"
    :accepted-file-types="acceptedFileTypes"
    :type="props.type"
    :before-remove-file="beforeRemoveFile"
  />
</template>
<style lang="scss">
.filepond--root {
  margin-bottom: 0;
}
</style>
